#tb-s3objects {
    width: "100% !Important";
}

a {
    color: #00B7FF;
}

body {
    font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
}

td {
    font: 12px "Lucida Grande", Helvetica, Arial, sans-serif;
}

.title {
    padding: 6px 12px;
}

.breadcrumb {
    margin-bottom: 0;
}

#navbuttons .btn {
    padding: 3px 6px;
}